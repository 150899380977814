<template>
  <div v-if="getUser" class="info-header">
    <div class="info-header__left">
      <img :src="getUserAvatar(getUser.avatarSrc?.fileSrc)" class="info-header__left-avatar" alt="">
      <div class="info-header__left-info" v-if="getUser?.role">
        <div class="info-nickname">
          <h2>{{getTitle}}</h2>
          <div v-if="getUser.role !== 'Moderator' && getUser.role !== 'Admin'" class="info-nickname__wrap">
<!--            <p v-if="getUser.role === 'Company' || getUser.role === 'Government'">{{ getUserName(getUser.firstName, getUser.lastName) }}</p>-->
            <span>{{ getSubTitle }}</span>
            <span class="info-nickname__wrap-nickname" v-if="getUser.role === 'Company' || this.getUser.role === 'Government'">{{ getUser.login }}</span>
          </div>
        </div>
        <div class="info-roll">
          <img :src="getRole.icon" alt="">
          <p>{{ $t(getRole.text) }}</p>
          <ui-tooltip v-if="getUser?.status === 'Rejected'" :isShow="getUser.roleRejectComment">
            <h2 class="title">{{ $t('role-not-confirmed') }}</h2>
            <h2 class="title">{{ $t('reason-of-not-confirmed') }}</h2>
            <p class="text">{{ getUser.roleRejectComment }}</p>
          </ui-tooltip>
        </div>
      </div>
      <div class="empty" v-else>
        {{ $t('empty') }}
      </div>
    </div>
    <div v-if="getRole.visibleRating" class="info-header__right">
      <div class="info-header__right-rating">
        <p>{{ $store.state.userRating[getUser.starRating] }}</p>
        <UiRating size="25" v-model="getUser.starRating" />
      </div>
      <div class="info-header__right-count" @click="showScoreWindow" :style="{cursor: this.getUser?.role === 'Active' ? 'pointer' : ''}">{{ getUser.score }}</div>
    </div>
    <ui-alert v-model="visibleAlertScore">
      <div class="ui-alert__title">{{ $t('current-balance') }}</div>
      <div class="ui-alert__subtitle">
        <p>{{ getUser?.score}}<span>{{ $t('points') }}</span></p>
      </div>
      <div class="ui-alert__items">
        <div class="ui-alert__items-item item" v-for="(item, key) in getOrders" :key="key">
          <p class="item__date">{{ getFullDate(item.createdAt).slice(0, 10)}}</p>
          <div class="item__info">
            <div @click=" visibleAlertScore = false, $router.push(`/work-requests/${item.id}`)" class="item__info-left">{{ $t('request', {data: item.id}) }}<p>{{ $t('type-number', {data: item.type?.name}) }}</p></div>
            <p class="item__info-right">{{item.activeScore}}</p>
          </div>
        </div>
        <p class="ui-alert__items-show" @click="loadMore" v-if="visibleLoadMore">{{ $t('show-more') }}</p>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import { getUserRole, getUserAvatar } from "@/utils/user";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import {getFullDate} from "../../../../utils/general";
export default {
  name: "ProfileMainInfoHeader",
  components: {
    UiAlert,
    UiTooltip,
    UiRating: () => import('@/components/ui/UiRating')
  },

  data() {
    return{
      getUserAvatar,
      visibleAlertScore: false,
      visibleLoadMore: true
    }
  },

  methods: {
    getFullDate,
    ...mapActions(['reqGetOrders']),
    ...mapMutations(['setOrders']),
    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.'
      }
    },

    showScoreWindow () {
      if(this.getUser?.role === 'Active') {
        this.reqGetOrders({
          userId: this.getUser.id,
          skip: 0,
          take: 10,
        }).then((res) => {
          this.setOrders(res.data)
          this.visibleAlertScore = !this.visibleAlertScore
          if(res.data.length !== 10) {
            this.visibleLoadMore = false
          }
        })
      }
    },

    loadMore () {
      this.reqGetOrders({
        userId: this.getUser.id,
        skip: this.getOrders.length,
        take: 10,
      }).then((res) => {
        this.setOrders(this.$store.state.ordersStore.orders.concat(res.data))
        if(res.data.length !== 10) {
          this.visibleLoadMore = false
        }
      })
    }
  },


  computed: {
    ...mapGetters(['usersData', 'getUser', 'getOrders']),
    getRole() {
      return getUserRole(this.getUser.role)
    },
    getTitle() {
      return this.getUser.role === 'Inspector' ? `${ this.getUserName(this.getUser.lastName, this.getUser.firstName, this.getUser?.middleName) }`  : '' ||
      (this.getUser.role === 'Company' || this.getUser.role === 'Government') ? this.getUser.companyName : '' ||
      this.getUser.role === 'Lawyer' ? `${ this.getUserName(this.getUser.lastName, this.getUser.firstName, this.getUser?.middleName) }` : '' ||
      (this.getUser.role === 'Moderator' || this.getUser.role === 'Admin' || this.getUser.role === 'Active') ? this.getUser.login : ''
    },
    getSubTitle() {
      return this.getUser.role === 'Company' || this.getUser.role === 'Government' || this.getUser.role === 'Active' ? this.getUserName(this.getUser.lastName, this.getUser.firstName, this.getUser?.middleName) :
      this.getUser.login ? this.getUser.login : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.info-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 30px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 30px;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 10px;
    }

    &-avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid #CE2121;

      @media (max-width: 992px) {
        width: 70px;
        height: 70px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 15px;

      @media (max-width: 768px) {
        gap: 10px;
      }

      @media (max-width: 450px) {
        max-width: 220px;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;

    @media (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 342px;
    }

    &-rating {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 768px) {
        flex-direction: row-reverse;

        ::v-deep .ui-rating {
          img {
            width: 20px !important;
            height: 20px !important;
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: #343432;

        @media (max-width: 992px) {
          font-size: 12px;
          line-height: 11px;
          color: #9A9A9A;
        }
      }
    }

    &-count {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #E21F1F;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 200px;
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #FFFFFF;
      padding: 14px 14px 11px;
    }
  }
}

.info-nickname {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: start;
  }

  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    color: #343432;
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    &-nickname {
      display: flex;
      align-items: center;
      gap: 5px;

      &:before {
        content: '';
        width: 5px;
        height: 5px;
        background: #343432;
        border-radius: 50%;

        @media (max-width: 1200px) {
          display: inline-block !important;
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 120.2%;
      color: #343432;

      @media (max-width: 992px) {
        font-family: "Gotham Pro Regular";
      }

      &:before {
        content: '';
        width: 5px;
        height: 5px;
        background: #343432;
        border-radius: 50%;

        @media (max-width: 1200px) {
          display: none;
        }
      }
    }
  }
}

.info-roll {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 22px;
    height: 22px;

    @media (max-width: 992px) {
      width: 10px;
      height: 10px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #343432;

    @media (max-width: 992px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.title{
  color: #1B1A1F;
  font-size: 14px;
}

.text {
  color: #1B1A1F;
  font-size: 14px;
  line-height: 120.2%;
  font-family: "Gotham Pro Regular";
}

::v-deep .empty {
  padding: 0 !important;
}

::v-deep .ui-alert {
  background: #FFFFFF !important;
  padding: 40px 0 0 0 !important;
  align-items: flex-start !important;
  max-width: 406px !important;
  width: 100%;

  &__title {
    text-align: start !important;
    width: 100%;
    padding-left: 20px;
    font-size: 16px !important;
  }

  &__subtitle {
    color: #02AD21;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: 112.2%;
    padding: 10px 20px;
    background-color: #F5F5F5;
    width: 100%;

    p {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: #1B1A1F;
        font-family: 'Gotham Pro Medium';
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-height: 400px;
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__date {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
        margin-left: 20px;
      }

      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background: #F5F5F5;

        &-left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: #1B1A1F;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;

          p {
            font-family: 'Gotham Pro Regular';
            font-size: 12px;
            font-weight: 400;
          }
        }

        &-right {
          color: #02AD21;
          font-size: 20px;
          font-weight: 400;
        }
      }
    }

    &-show {
      color: #9A9A9A;
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
