<template>
  <div class="tooltip">
    <img :src="require(`@/assets/svg/icon-attention-${color}.svg`)" alt="" class="tooltip__button" @click="visibleAlert=alert" @mousemove="show=true" @mouseleave="show=false"/>
    <transition name="slide-bottom">
      <div class="tooltip__window" v-if="show && isShow">
        <slot></slot>
      </div>
    </transition>
    <UiAlert v-model="visibleAlert">
      <ui-textarea
          v-model="descriptionValue"
          radius="10" height="80"
          :label="$t('hints')"
      />
      <ui-textarea
          v-model="descriptionValueEn"
          radius="10" height="80"
          :label="$t('english')"
      />
      <div class="ui-alert__action">
        <ui-button @click="saveData">{{ $t('save') }}</ui-button>
        <ui-button @click="visibleAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import descriptionProblems from "../template/work-requests/ViewRequests/DescriptionProblems.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiTextarea from "@/components/ui/UiTextarea.vue";

export default {
  name: "UiTooltip",
  components: {UiTextarea, UiButton, UiAlert},
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    descriptionEn: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'red'
    },
    alert: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    isShow: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      show: false,
      visibleAlert: false,
      descriptionValue: '',
      descriptionValueEn: ''
    }
  },

  computed: {
    descriptionProblems() {
      return descriptionProblems
    }
  },

  methods: {
    saveData () {
      this.visibleAlert = false;
      let payload = {
        description: this.descriptionValue,
        descriptionEn: this.descriptionValueEn
      }
      setTimeout(() => {
        this.$emit('updateDescription', payload)
      }, 300)
    }
  },

  mounted () {
    this.descriptionValue = this.data?.description
    this.descriptionValueEn = this.data?.descriptionInt?.en
  }
}
</script>


<style lang="scss" scoped>
.tooltip {
  position: relative;
  &:hover {
    z-index: 1000;
  }

  &__button {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  &__window {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 22px;
    padding: 20px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
    width: 404px;
    transition: 0.3s;

    .title{
      color: #1B1A1F;
      font-size: 14px;
    }

    .text {
      color: #1B1A1F;
      font-size: 14px;
      line-height: 120.2%;
      font-family: "Gotham Pro Regular";
    }
  }
}
</style>
